/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a",
    p: "p",
    section: "section",
    h2: "h2",
    div: "div",
    h3: "h3",
    code: "code",
    h4: "h4"
  }, _provideComponents(), props.components), {Emoji} = _components;
  if (!Emoji) _missingMdxReference("Emoji", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#motivation"
  }, "Motivation"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#current-pages"
  }, "Current Pages")), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#tech-stack"
  }, "Tech Stack"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#bamboo-css"
  }, "Bamboo CSS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#gatsby"
  }, "Gatsby"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#course--resources"
  }, "Course / Resources")), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#github-pages"
  }, "GitHub Pages")), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#credits"
  }, "Credits"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#logo-httpsandynguyendevlogojpeg"
  }, "Logo (https://andynguyen.dev/logo.jpeg)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#fonts"
  }, "Fonts")), "\n"), "\n"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#conclusion"
  }, "Conclusion")), "\n"), "\n", React.createElement(_components.p, null, "An inaugural first post to the blog ", React.createElement(Emoji, {
    symbol: "😎"
  }), ", which goes through the motivation and technical decisions of making this site."), "\n", React.createElement(_components.section, null, React.createElement(_components.h2, {
    id: "motivation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#motivation",
    "aria-label": "motivation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Motivation"), React.createElement(_components.p, null, "For the past five years or so, my personal site has always been a single static HTML file with just my contact information. For 2022, I've decided to revamp the site to include a blog so I could:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "show progression in both writing and software development"), "\n", React.createElement(_components.li, null, "share knowledge via tutorials or opinionated posts"), "\n"), React.createElement(_components.p, null, "Hopefully, this site will highlight my progression in both crafts and provide me with something to reflect on in the future. Also, I've learnt a lot from individual blogs over my professional career and wanted to contribute something back. All in all, I believe that having a personal website is a good way to advance your professional development."), React.createElement(_components.section, null, React.createElement(_components.h3, {
    id: "current-pages",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#current-pages",
    "aria-label": "current pages permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Current Pages"), React.createElement(_components.p, null, "Currently, there are three pages and one template."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Home - ", React.createElement(_components.code, null, "/")), "\n", React.createElement(_components.li, null, "About - ", React.createElement(_components.code, null, "/about/")), "\n", React.createElement(_components.li, null, "Blog - ", React.createElement(_components.code, null, "/blog/")), "\n", React.createElement(_components.li, null, "Blog Posts - ", React.createElement(_components.code, null, "/blog/{post-slug}/")), "\n"))), "\n", React.createElement(_components.section, null, React.createElement(_components.h2, {
    id: "tech-stack",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tech-stack",
    "aria-label": "tech stack permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tech Stack"), React.createElement(_components.p, null, "For this site, I chose three key technologies:"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/rilwis/bamboo",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Bamboo CSS"), ", classless CSS library, for base styling"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Gatsby v4"), ", a React framework, for static site generation"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://pages.github.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "GitHub Pages"), " for hosting"), "\n"), React.createElement(_components.section, null, React.createElement(_components.h3, {
    id: "bamboo-css",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bamboo-css",
    "aria-label": "bamboo css permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Bamboo CSS"), React.createElement(_components.p, null, "Not being the best at CSS, I wanted a plug-and-play system and decided to go with a classless CSS library. Typically, a classless CSS library is for prototyping and is very lightweight. Luckily, I found ", React.createElement(_components.a, {
    href: "https://github.com/dbohdan/classless-css",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "this handy repo"), " and ended up on ", React.createElement(_components.a, {
    href: "https://github.com/rilwis/bamboo",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Bamboo CSS"), " because it looked best to me and had customizable CSS variables. To customize the library/theme, I added my choice of fonts and switched out some colors. Overall, I enjoy using Bamboo CSS due to its lightweight and customizable nature. Alternatively, I think ", React.createElement(_components.a, {
    href: "https://tailwindcss.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Tailwind CSS"), " and ", React.createElement(_components.a, {
    href: "https://open-props.style/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Open Props"), " are interesting CSS systems to consider.")), React.createElement(_components.section, null, React.createElement(_components.h3, {
    id: "gatsby",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#gatsby",
    "aria-label": "gatsby permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Gatsby"), React.createElement(_components.p, null, "Already knowing React and wanting a static site (for SEO, security, and performance reasons), I naturally went with the ", React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Gatsby"), " ecosystem. Gatsby is a React-based framework with an incredible static site generator and plugin library. Coming from a JavaScript and WordPress background, the Gatsby (CLI and community) development experience has been fantastic. All in all, I highly recommend Gatsby for any blog or static site project, especially if you already know React. Otherwise, I believe ", React.createElement(_components.a, {
    href: "https://astro.build/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Astro"), " and ", React.createElement(_components.a, {
    href: "https://nextjs.org/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Next.js"), " are viable alternatives to Gatsby."), React.createElement(_components.section, null, React.createElement(_components.h4, {
    id: "course--resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#course--resources",
    "aria-label": "course  resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Course / Resources"), React.createElement(_components.p, null, "I highly recommend these resources for anyone building a Gatsby project."), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mastergatsby.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Wes Bos' Master Gatsby"), " - highly recommended, Wes Bos is an awesome instructor"), "\n", React.createElement(_components.li, null, "https://github.com/wesbos/master-gatsby"), "\n", React.createElement(_components.li, null, "https://github.com/gatsbyjs/tutorial-example"), "\n", React.createElement(_components.li, null, "https://github.com/gatsbyjs/gatsby-starter-blog"), "\n", React.createElement(_components.li, null, "https://github.com/taniarascia/taniarascia.com"), "\n", React.createElement(_components.li, null, "https://github.com/jlengstorf/gatsby-theme-jason-blog"), "\n"))), React.createElement(_components.section, null, React.createElement(_components.h3, {
    id: "github-pages",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#github-pages",
    "aria-label": "github pages permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "GitHub Pages"), React.createElement(_components.p, null, "Currently, this site is hosted on ", React.createElement(_components.a, {
    href: "https://pages.github.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "GitHub Pages"), ", and I didn't see a need to switch hosting providers. Using GitHub Pages is convenient because it's the same platform as the git repository. However, there is a potential downside: the git repo (for the free tier) needs to be public, which isn't problematic for a personal website like this one. Alternatively, I would consider ", React.createElement(_components.a, {
    href: "https://www.netlify.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Netlify"), " and ", React.createElement(_components.a, {
    href: "https://pages.cloudflare.com/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Cloudflare Pages"), "."), React.createElement(_components.p, null, "Check out my tutorial about ", React.createElement(_components.a, {
    href: "/blog/deploy-gatsby-github-pages-gh-pages-github-actions/"
  }, "deploying to GitHub Pages with gh-pages and GitHub Actions"), "."))), "\n", React.createElement(_components.section, null, React.createElement(_components.h2, {
    id: "credits",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#credits",
    "aria-label": "credits permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Credits"), React.createElement(_components.p, null, "Amazing creative assets I used:"), React.createElement(_components.section, null, React.createElement(_components.h3, {
    id: "logo-httpsandynguyendevlogojpeg",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#logo-httpsandynguyendevlogojpeg",
    "aria-label": "logo httpsandynguyendevlogojpeg permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Logo (https://andynguyen.dev/logo.jpeg)"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Made with ", React.createElement(_components.a, {
    href: "https://express.adobe.com/express-apps/logomaker/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Adobe Logo Maker"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Fonts: Lato-Regular,Bellfort"), "\n", React.createElement(_components.li, null, "Colors: F2EEE4,5065A8"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://thenounproject.com/term/keyboard/9980",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Keyboard Icon")), "\n"), "\n"), "\n")), React.createElement(_components.section, null, React.createElement(_components.h3, {
    id: "fonts",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#fonts",
    "aria-label": "fonts permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Fonts"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rsms.me/inter/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Inter"), " for sans-serif typeface"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.jetbrains.com/lp/mono/",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "JetBrains Mono"), " for monospace typeface"), "\n"))), "\n", React.createElement(_components.section, null, React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), React.createElement(_components.p, null, "It's been a lot of fun putting this site together, especially going back to the basics (HTML and CSS).\nAnyone who wants to create their own personal website and have their own little part of the internet should do so.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
